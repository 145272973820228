<!--
 * @Author: xwen
 * @Date: 2020-02-27 14:09:50
 * @LastEditTime: 2021-03-29 17:18:57
 * @LastEditors: Please set LastEditors
 * @Description:  问答题
 -->

<template>
  <div class="question question-question-answer-exam">
    <div class="title">
      <span class="kinds">问答题</span>
      <span class="stem" v-html="(question.stem)" :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*26}px;color:${question.colorMode=='day'?'#333':'#ddd'}`"></span>
    </div>
    <div class="content">
      <van-field
        :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*26}px;color:${question.colorMode=='day'?'#333':'#ddd'};background:${question.colorMode=='day'?'#fff':'#404040'}`"
        @input="inputSubmit"
        v-model="userAnswer"
        :class="`${question.colorMode=='day'?'day':'night'}`"
        autosize
        type="textarea"
        placeholder="请输入答案"
        label="作答:"
        :label-width="`${question.fontSizeMultiple*40}px`" />
      <!-- <van-button
        v-if="!isSubmit"
        class="submit"
        round size="small"
        color="#18A6F3"
        type="info"
        @click="submit">提 交</van-button>
      <van-button
        v-if="isSubmit"
        class="submit"
        round size="small"
        color="#aaa"
        type="info"
        @click="submit">已提交</van-button> -->
    </div>
    <!-- 解析 -->
    <div
    v-html="question.answer || '暂无'"
    v-if="isExercises && !hasAnswered(question.userAnswer, question.answer)" class="analysis">
      参考答案：<span>{{  }}</span>
      <div class="anly">
        解析：<span>{{ question.analysis || '暂无' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
/*
 * 去除字符串中的所有空格
 */
function removeAllSpace (str) {
  if (!str || typeof str !== 'string') return str
  return str.replace(/\s+/g, '').replace('&nbsp;', '').replace(/ /g, '')
}
export default {
  name: 'QuestionAnswer',
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    return {
      userAnswer: this.question.userAnswer || '',
      isSubmit: !!this.question.userAnswer
    }
  },
  computed: {
    isRight () {
      return function (userAnswer, answer) {
        if (!userAnswer) {
          return true
        } else {
          return userAnswer === answer.trim()
        }
      }
    },
    hasAnswered () {
      return function (userAnswer, answer) {
        if (!userAnswer) {
          return true
        }
      }
    }
  },
  methods: {
    inputSubmit () {
      let obj = {
        ...this.question,
        questionId: this.question.id,
        userAnswer: this.userAnswer
      }
      if (!removeAllSpace(obj.userAnswer)) {
        Toast({
          message: '请选择或填写答案'
        })
        return
      }
      // this.isSubmit = true
      this.$emit('change', obj)
    }
    // submit () {
    //   let obj = {
    //     ...this.question,
    //     questionId: this.question.id,
    //     userAnswer: this.userAnswer
    //   }
    //   if (!removeAllSpace(obj.userAnswer)) {
    //     Toast({
    //       message: '请选择或填写答案'
    //     })
    //     return
    //   }
    //   this.isSubmit = true
    //   this.$emit('change', obj)
    // }
  }
}
</script>

<style lang="scss">
  .question-question-answer-exam {
    .stem {
      display: block;
      p {
        margin: 0;
        padding: 0;
        color: #333;
        font-size: 16px;
        line-height: 26px;
      }
      span {
        color: #333;
        font-size: 16px;
        line-height: 26px;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 70vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        max-width: 100%;
        height: 40px;
        vertical-align: middle;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .question {
    width: 100%;
    height: calc(100vh - 58px - 30px);
    box-sizing: border-box;
    padding: 17px 15px 15px 15px;
    overflow: auto;
    .title {
      color: #333;
      font-size: 16px;
      line-height: 26px;
      .kinds {
        display: inline-block;
        line-height: 17px;
        color: #fff;
        text-align: center;
        background: #18A6F3;
        border-radius: 8px 8px 0 8px;
        margin-right: 16px;
        padding: 2px 6px;
        font-size: 12px;
      }
      .score {
        color: #999;
      }
    }
    .img {
      width: 80%;
      height: auto;
      display: block;
      margin: 20px auto;
    }
    .content {
      margin-top: 30px;
      margin-bottom: 30px;
      .submit {
        display: block;
        margin: 20px auto;
      }
      .night{
        ::v-deep .van-field__control{
          color:#ddd;
        }
      }
    }
    .analysis {
      margin: 30px 15px 70px;
      padding: 14px 18px;
      font-size: 15px;
      color: #333;
      background: #eee;
      border-radius: 3px;
      line-height: 25px;
      overflow: hidden;
      word-wrap: break-word;
      span {
        color: #999;
      }
      .anly {
        text-indent: 30px;
      }
    }
  }
</style>
