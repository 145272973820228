<!--
 * @Author: xwen
 * @Date: 2020-02-25 11:10:27
 * @LastEditTime: 2021-03-27 17:06:17
 * @LastEditors: Please set LastEditors
 * @Description:
 -->
<template>
  <div>
    <van-swipe ref="swiper" class="my-swipe" :initial-swipe="currentIndex" :loop="false" :show-indicators="false" @change="onChange">
      <van-swipe-item v-for="(item, index) in examList" :key="index">
        <single-choice v-if="item.typeId == 1" :question="item" :num="index + 1" :isExercises="isExercises" @change="answer"></single-choice>
        <multiple-choice v-else-if="item.typeId == 2" :question="item" :num="index + 1" :isExercises="isExercises" @change="answer"></multiple-choice>
        <Uncertain v-else-if="item.typeId == 3" :question="item" :num="index + 1" :isExercises="isExercises" @change="answer"></Uncertain>
        <judge v-else-if="item.typeId == 4" :question="item" :num="index + 1" :isExercises="isExercises" @change="answer"></judge>
        <Fill v-else-if="item.typeId == 5" :question="item" :num="index + 1" :isExercises="isExercises" @change="answer"></Fill>
        <question-answer v-else-if="item.typeId == 6" :question="item" :num="index + 1" :isExercises="isExercises" @change="answer"></question-answer>
        <analysis v-else-if="item.typeId == 7" :question="item" :num="index + 1" :isExercises="isExercises" @change="answer"></analysis>
        <compatibility v-else-if="item.typeId == 8"
        :question="item" :num="index + 1" :isExercises="isExercises"
        @change="answer"></compatibility>
        <div class="loading" v-else>题目加载中~</div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import SingleChoice from './singleChoice'
import MultipleChoice from './multipleChoice'
import Uncertain from './uncertain'
import Fill from './fill'
import Judge from './judge'
import QuestionAnswer from './questionAnswer'
import Analysis from './analysis'
import Compatibility from './compatibility'
export default {
  components: {
    SingleChoice,
    MultipleChoice,
    Uncertain,
    Fill,
    Judge,
    QuestionAnswer,
    Analysis,
    Compatibility
  },
  props: {
    examList: {
      type: Array,
      default: function () {
        return []
      }
    },
    currentIndex: {
      type: Number,
      default: function () {
        return 0
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    return {
      result: null
    }
  },
  methods: {
    answer (val) {
      console.log('单选题选择了的呀', val)
      this.$emit('question-result', val)
    },
    onChange (e) {
      document.body.click()
      this.$nextTick(() => {
        this.$emit('question-index', e)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
  text-align: center;
}
</style>
