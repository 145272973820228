<!--
 * @Date: 2020-02-06 20:53:44
 * @LastEditors: xwen
 * @Author: xw
 * @LastEditTime: 2020-04-16 10:29:49
 * @Description: 答题卡
 -->
<template>
  <div class="modal" @click.self="close">
    <div class="answer-sheet" :style="`background:${userSetting.colorModeExam=='day'?'#fff':'#000'}`">
      <!-- <div class="topics">
        <img src="@/assets/images/topic.png" alt="">
        <span class="num">{{ sumed }}</span>/{{ examTotal }}
      </div> -->
      <div class="topics">
        <icon-svg :iconClass="`${userSetting.colorModeExam=='night'?'icon-shensemoshi-tishu3x':'icon-qiansemoshi-tishu3x'}`" className="icon-class"></icon-svg>
        <span :style="`color:${userSetting.colorModeExam=='day'?'#333':'#ddd'}`">{{ sumed }}/{{ examTotal }}</span>
      </div>
      <div :class="{'topics1':true,'night':userSetting.colorModeExam=='night'}">
        <div class="answer" :style="`color:${userSetting.colorModeExam=='day'?'#333':'#ddd'}`">
          <span class="answered"></span>已答 {{ answeredSum }} 道
        </div>
        <div class="answer" :style="`color:${userSetting.colorModeExam=='day'?'#333':'#ddd'}`">
          <span></span>未答 {{ leftSum }} 道
        </div>
        <div class="clear" @click="submit" v-show="false">
          <img src="@/assets/img/paper.png" alt="">交卷
        </div>
      </div>
      <div class="t-items">
        <div class="item-box">
          <div class="t-item" :class="{ 'answered': item.userAnswer, 'none': !item.userAnswer, 'night':userSetting.colorModeExam=='night' }" v-for="(item, index) in answerCardList" :key="index" @click="toCurrentExam(index)">
            {{ index + 1 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    sumed: {
      type: Number,
      default: function () {
        return 0
      }
    },
    answeredSum: {
      type: Number,
      default: function () {
        return 0
      }
    },
    leftSum: {
      type: Number,
      default: function () {
        return 0
      }
    },
    answerCardList: {
      type: Array,
      default: function () {
        return []
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    return {
      examTotal: this.answerCardList.length
    }
  },
  created () {
    console.log(this.userSetting.colorModeExam, 111)
  },
  computed: {
    ...mapGetters({ userSetting: 'userSetting' }),
    answerStatus () {
      return function (item) {
        const userAnswer = item.userAnswer
        if (this.isExercises) {
          return userAnswer ? ((item.rightFlag === 1 || item.rightFlag === '1') ? '' : 'error') : 'none'
        } else {
          if (userAnswer) return 'answered'
          return userAnswer ? 'answered' : 'none'
        }
      }
    }
  },
  methods: {
    submit () {
      this.$emit('submit')
    },
    close () {
      this.$emit('close')
    },
    toCurrentExam (index) {
      this.$emit('to-current-exam', index)
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal {
    width: 100%;
    height: 100vh;
    background: rgba($color: #000000, $alpha: .2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
  }
  .answer-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 362px;
    background: #fff;
    box-sizing: border-box;
    .topics {
      padding: 15px;
      font-size: 13px;
      color: #999;
      img {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 7px;
      }
      .num {
        color: #333;
      }
      .reback {
        display: inline-block;
        color: #7AB703;
        margin-left: 23px;
      }
      .setting {
        width: 18px;
        height: 18px;
        float: right;
      }
    }
    .topics1 {
      padding: 8px 15px 18px 15px;
      font-size: 13px;
      color: #333;
      border-bottom: 1px solid #eaeaea;
      &.night{
          border-bottom: 1px solid #2c2c2c;
      }
      overflow: hidden;
      .answer {
        float: left;
        margin-right: 26px;
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 8px;
          border: 1px solid #c6c6c6;
          border-radius: 2px;
          box-sizing: border-box;
        }
        img {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 8px;
          border-radius: 2px;
          box-sizing: border-box;
        }
        .answered {
          border: 1px solid #18A6F3;
        }
      }
      .clear {
        float: right;
        display: flex;
        align-items: center;
        img {
          display: inline-block;
          margin-right: 7px;
          width: 12px;
          height: 12px;
        }
      }
    }
    .t-items {
      padding: 0 15px;
      overflow-y: auto;
      height: 310px;
      .item-box {
        display: flex;
        flex-flow: wrap;
        padding-bottom: 50px;
        .t-item {
          margin: 14px 15px 0 0;
          width: 32px;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          color: #333;
          &.night{
            color: #c6c6c6;
          }
          text-align: center;
          border: 1px solid #18A6F3;
          border-radius: 5px;
          box-sizing: border-box;
          &:nth-child(7n) {
            margin-right: 0;
          }
          &.none {
            border-color: #c6c6c6;
            color: #c6c6c6;
          }
          &.error {
            border-color: #ff790d;
            color: #333;
          }
        }
      }
    }
  }
</style>
