<!--
 * @Author: xwen
 * @Date: 2020-02-27 09:43:37
 * @LastEditTime: 2020-04-07 16:15:02
 * @LastEditors: xwen
 * @Description: 填空题
 -->
<template>
  <div class="question question-children-fill-exam">
    <div class="title">
      <div>
        <span class="kinds">填空题</span>
      </div>
      <span v-html="question.stem" class="stem" :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*26}px;color:${question.colorMode=='day'?'#333':'#ddd'}`"></span>
    </div>
    <div class="content">
      <van-field
        :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*26}px;color:${question.colorMode=='day'?'#333':'#ddd'};background:${question.colorMode=='day'?'#fff':'#404040'}`"
        @input="onInput"
        :class="`${question.colorMode=='day'?'day':'night'}`"
        style="font-size: 16Px"
        v-for="(item, index) in list"
        :key="index"
        :placeholder="`请输入第${index + 1}空`"
        :label="index === 0 ? '作答:' : ''"
        v-model="list[index]"
        :label-width="`${question.fontSizeMultiple*40}px`" />
      <!-- <van-button
        v-if="!isSubmited"
        class="submit"
        round size="small"
        color="#18A6F3"
        type="info"
        @click="submit">提 交</van-button>
      <van-button
        v-else
        class="submit"
        round size="small"
        color="#aaa"
        type="info"
        @click="submit">已提交</van-button> -->
    </div>
    <!-- 解析 -->
    <div v-if="isExercises && !hasAnswered(question.userAnswer, question.answer)" class="analysis">
      参考答案：<span>{{ answerStr }}</span>
      <div class="anly">
        解析：<span>{{ question.analysis || '无' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
/*
 * 去除字符串中的所有空格
 */
function removeAllSpace (str) {
  if (!str || typeof str !== 'string') return str
  return str.replace(/\s+/g, '').replace('&nbsp;', '').replace(/ /g, '')
}
export default {
  name: 'Fill',
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  watch: {
    question: {
      handler (val) {
        console.log('val', val)
      },
      immediate: true
    }
  },
  computed: {
    answerStr () {
      try {
        const answerArr = JSON.parse(this.question.answer).join(' | ')
        return answerArr
      } catch (e) {
        return this.question.answer
      }
    },
    isRight () {
      return function (userAnswer, answer) {
        if (!userAnswer) {
          return true
        } else {
          return userAnswer === answer.trim()
        }
      }
    },
    hasAnswered () {
      return function (userAnswer, answer) {
        if (!userAnswer) {
          return true
        }
      }
    }
  },
  data () {
    const list = this.fillNum()
    return {
      userAnswer: this.question.userAnswer || '',
      list: list,
      isSubmited: !!this.question.userAnswer
    }
  },
  methods: {
    onInput () {
      // console.log('ssss')
      // this.isSubmited = false
      console.log('list', this.list)
      const isAnswerNull = this.list.every(item => removeAllSpace(item) === '')
      if (isAnswerNull) {
        Toast('请选择或填写答案')
        return
      }
      let obj = {
        ...this.question,
        questionId: this.question.id,
        userAnswer: JSON.stringify(this.list)
      }
      // this.isSubmited = true
      this.$emit('change', obj)
    },
    fillNum () {
      if (this.question.userAnswer) {
        return JSON.parse(this.question.userAnswer)
      } else {
        const arr = JSON.parse(this.question.answer)
        let arr2 = []
        for (let i = 0; i < arr.length; i++) {
          arr2[i] = ''
        }
        return arr2
      }
    }
    // submit () {
    //   console.log('list', this.list)
    //   const isAnswerNull = this.list.every(item => removeAllSpace(item) === '')
    //   if (isAnswerNull) {
    //     Toast('请选择或填写答案')
    //     return
    //   }
    //   let obj = {
    //     ...this.question,
    //     questionId: this.question.id,
    //     userAnswer: JSON.stringify(this.list)
    //   }
    //   this.isSubmited = true
    //   this.$emit('change', obj)
    // }
  }
}
</script>

<style lang="scss">
  .question-children-fill-exam {
    .stem {
      display: block;
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 70vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        height: 40px;
        vertical-align: middle;
      }
    }
    .quesion-analysis {
      display: block;
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 60vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        height: 40px;
        vertical-align: middle;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .question {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 0px 15px 0px;
    .title {
      color: #333;
      font-size: 16px;
      line-height: 26px;
      .kinds {
        display: inline-block;
        line-height: 17px;
        color: #FFA106;
        border:1px solid rgba(245,166,35,1);
        background:rgba(245,166,35,0.15);
        text-align: center;
        border-radius: 8px 8px 0 8px;
        margin-right: 16px;
        padding: 2px 6px;
        font-size: 12px;
      }
      .score {
        color: #999;
      }
    }
    .img {
      width: 80%;
      height: auto;
      display: block;
      margin: 20px auto;
    }
    .content {
      margin-top: 30px;
      .submit {
        display: block;
        margin: 20px auto;
      }
      .night{
        ::v-deep .van-field__control{
          color:#ddd;
        }
      }
    }
    .analysis {
      margin: 30px 15px 70px;
      padding: 14px 18px;
      font-size: 15px;
      color: #333;
      background: #eee;
      border-radius: 3px;
      line-height: 25px;
      overflow: hidden;
      word-wrap: break-word;
      span {
        color: #999;
      }
      .anly {
        text-indent: 30px;
      }
    }
  }
</style>
