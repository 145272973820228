<!--
 * @Author: xwen
 * @Date: 2020-02-27 09:24:43
 * @LastEditTime: 2021-03-17 17:11:52
 * @LastEditors: Please set LastEditors
 * @Description: 判断题
 -->

<template>
  <div class="question question-judge-exam">
    <div class="title">
      <div>
        <span class="kinds">判断题</span>
      </div>
      <span v-html="question.stem" class="stem" :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*26}px;color:${question.colorMode=='day'?'#333':'#ddd'}`"></span>
    </div>
    <div class="content">
      <van-radio-group v-model="userAnswer" direction="horizontal" @change="change">
        <van-radio name="true" :checked-color="userAnswer == question.answer ? '#18A6F3' : '#18A6F3'" :class="`${question.fontSizeMultiple==2?'margin':''}`">
          <span :class="getSingleResult(userAnswer, true, question.answer)" :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*16}px;color:${question.colorMode=='day'?'#333':'#ddd'}`">对</span>
        </van-radio>
        <van-radio name="false" :checked-color="userAnswer == question.answer ? '#18A6F3' : '#18A6F3'" :class="`${question.fontSizeMultiple==2?'margin':''}`">
          <span :class="getSingleResult(userAnswer, false, question.answer)" :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*16}px;color:${question.colorMode=='day'?'#333':'#ddd'}`">错</span>
        </van-radio>
      </van-radio-group>
    </div>
    <!-- <div style="text-align: center;">
      <van-button
        v-if="!isSubmited"
        class="submit"
        round size="small"
        color="#18A6F3"
        type="info"
        @click="submit">提 交</van-button>
      <van-button
        v-else
        class="submit"
        round size="small"
        color="#aaa"
        type="info"
        @click="submit">已提交</van-button>
    </div> -->
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'Judge',
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    return {
      userAnswer: this.question.userAnswer ? this.question.userAnswer : '',
      isSubmited: !!this.question.userAnswer,
      result: ''
    }
  },
  computed: {
    isRight () {
      return function (userAnswer, answer) {
        if (!userAnswer) { // 未作答
          return true
        } else {
          return String(userAnswer) === String(answer)
        }
      }
    },
    hasAnswered () {
      return function (userAnswer, answer) {
        if (!userAnswer) { // 未作答
          return true
        }
      }
    },
    getSingleResult () {
      return function (userAnswer, name, answer) {
        let result = ''
        if (this.isExercises) {
          result = String(userAnswer) === String(name) ? (String(userAnswer) === String(answer) ? 'right' : '') : ''
        } else {
          result = String(userAnswer) === String(name) ? 'right' : ''
        }
        return result
      }
    }
  },
  methods: {
    change (e) {
      // this.isSubmited = false
      console.log(this.userAnswer)
      let obj = {
        ...this.question,
        questionId: this.question.id,
        userAnswer: this.userAnswer
      }
      if (!obj.userAnswer) {
        Toast({
          message: '请选择或填写答案'
        })
        return
      }
      // this.isSubmited = true
      this.$emit('change', obj)
    }
    // submit () {
    //   console.log(this.userAnswer)
    //   let obj = {
    //     ...this.question,
    //     questionId: this.question.id,
    //     userAnswer: this.userAnswer
    //   }
    //   if (!obj.userAnswer) {
    //     Toast({
    //       message: '请选择或填写答案'
    //     })
    //     return
    //   }
    //   this.isSubmited = true
    //   this.$emit('change', obj)
    // }
  }
}
</script>

<style lang="scss">
  .question-judge-exam {
    .stem {
      display: block;
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 70vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        max-width: 100%;
        vertical-align: middle;
      }
    }
    .quesion-analysis {
      display: block;
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 60vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        height: 40px;
        vertical-align: middle;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .question {
    width: 100%;
    height: calc(100vh - 58px - 30px);
    box-sizing: border-box;
    padding: 17px 15px 15px 15px;
    overflow: auto;
    .title {
      color: #333;
      font-size: 16px;
      line-height: 26px;
      .kinds {
        display: inline-block;
        line-height: 17px;
        color: #fff;
        text-align: center;
        background: #18A6F3;
        border-radius: 8px 8px 0 8px;
        margin-right: 16px;
        padding: 2px 6px;
        font-size: 12px;
      }
      .score {
        color: #999;
      }
    }
    .img {
      width: 80%;
      height: auto;
      display: block;
      margin: 20px auto;
    }
    .content {
      margin-top: 30px;
      margin-bottom: 30px;
      .margin{
        ::v-deep .van-radio__icon.van-radio__icon--round{
          margin-top: 7px;
        }
      }
      .van-radio {
        margin-bottom: 15px;
        color: #333;
        min-height: 38px;
        align-items: flex-start;
        .right {
          color: #18A6F3 !important;
        }
        .error {
          color: #ff790d;
        }
      }
      .radio {
        display: inline-block;
        width: 25px;
        height: 25px;
        line-height: 25px;
        margin: 0 10px 0 2px;
        text-align: center;
        border-radius: 50%;
        box-shadow: 0px 2px 4px 0 rgba($color: #000000, $alpha: .2);
        font-size: 15px;
        color: #333;
        &.right {
          background: #18A6F3 !important;
          color: #fff;
        }
        &.error {
          background: #ff790d;
          color: #fff;
        }
      }
    }
    .analysis {
      margin: 30px 15px 70px;
      padding: 14px 18px;
      font-size: 15px;
      color: #333;
      background: #eee;
      border-radius: 3px;
      line-height: 25px;
      overflow: hidden;
      word-wrap: break-word;
      span {
        color: #999;
      }
      .anly {
        text-indent: 30px;
      }
    }
  }
</style>
