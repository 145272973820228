<template>
  <div class="question-analysis question-analysis-exam">
    <div class="title">
      <span class="kinds">材料分析题</span>
      <div v-html="question.stem" class="stem" :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*26}px;color:${question.colorMode=='day'?'#333':'#ddd'}`"></div>
    </div>
    <div class="question-tip" :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*26}px;color:${question.colorMode=='day'?'#333':'#ddd'}`">请认真阅读上述材料，完成以下题目：</div>
    <div class="content" v-for="(item, index) in childrenList" :key="index">
      <single-choice v-if="item.typeId == 1" :question="item" :num="index + 1" :isExercises="true" @change="answer"></single-choice>
      <multiple-choice v-else-if="item.typeId == 2" :question="item" :num="index + 1" :isExercises="true" @change="answer"></multiple-choice>
      <Uncertain v-else-if="item.typeId == 3" :question="item" :num="index + 1" :isExercises="true" @change="answer"></Uncertain>
      <judge v-else-if="item.typeId == 4" :question="item" :num="index + 1" :isExercises="true" @change="answer"></judge>
      <Fill v-else-if="item.typeId == 5" :question="item" :num="index + 1" :isExercises="true" @change="answer"></Fill>
      <question-answer v-else-if="item.typeId == 6" :question="item" :num="index + 1" :isExercises="true" @change="answer"></question-answer>
    </div>
  </div>
</template>

<script>
import SingleChoice from './analysisQuestions/singleChoice'
import MultipleChoice from './analysisQuestions/multipleChoice'
import Uncertain from './analysisQuestions/uncertain'
import Fill from './analysisQuestions/fill'
import Judge from './analysisQuestions/judge'
import QuestionAnswer from './analysisQuestions/questionAnswer'
export default {
  components: {
    SingleChoice,
    MultipleChoice,
    Uncertain,
    Fill,
    Judge,
    QuestionAnswer
  },
  mounted () {
    console.log(this.question.childrenList, '<<asdf')
  },
  computed: {
    childrenList: function () {
      return this.question.childrenList.filter(item => (item.typeId === 1 || item.typeId === 2 || item.typeId === 3 || item.typeId === 4 || item.typeId === 5 || item.typeId === 6))
    }
  },
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  methods: {
    answer (val) {
      this.$emit('change', { ...val, parentQuestion: this.question })
    },
    onChange (e) {
      // this.$nextTick(() => {
      //   this.$emit('question-index', e)
      // })
    }
  }
}
</script>
<style lang="scss">
  .question-analysis-exam {
    .stem {
      display: block;
      p {
        margin: 0;
        padding: 0;
        color: #333;
        font-size: 16px;
        line-height: 26px;
      }
      span {
        color: #333;
        font-size: 16px;
        line-height: 26px;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 70vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        max-width: 100%;
        height: 40px;
        vertical-align: middle;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .question-analysis {
    .question-tip {
      font-size: 14px;
      color: #B2B2B2;
      margin-top: 40px;
    }
    width: 100%;
    box-sizing: border-box;
    height: calc(100vh - 58px);
    overflow: auto;
    padding: 17px 15px 55px 15px;
    .title {
      color: #333;
      font-size: 16px;
      line-height: 26px;
      .kinds {
        display: inline-block;
        line-height: 17px;
        color: #fff;
        text-align: center;
        background: #18A6F3;
        border-radius: 8px 8px 0 8px;
        margin-right: 16px;
        padding: 2px 6px;
        font-size: 12px;
      }
      .score {
        color: #999;
      }
    }
    .img {
      width: 80%;
      height: auto;
      display: block;
      margin: 20px auto;
    }
  }
</style>
