<!--
 * @Date: 2020-02-08 11:41:57
 * @LastEditors: Hc
 * @Author: xw
 * @LastEditTime: 2021-10-09 14:52:57
 * @Description: 练习题 /exam/exercises?courseId=1&userToken=
 -->
<template>
  <div :class="`container exam-container ${checked?'night':''}`" :style="`background:${checked?'#000':'#fff'}`">
    <!-- 提示弹窗 -->
    <div v-if="!show" class="shadow">
      <div class="tip">
        <div class="left">
          往左滑动至下一题
          <img src="@/assets/img/guideHandleft.png" alt="">
        </div>
        <div class="right">
          往右滑动至上一题
          <img src="@/assets/img/guideHandright.png" alt="">
        </div>
      </div>
      <div class="know" @click="clickKnow">我知道了</div>
    </div>
    <van-count-down style="position:fixed" v-show="showCountdown" ref="count-down" :auto-start="false" @finish="onExamFinished" :time="timeLeft">
      <!-- <div slot-scope="scpre">{{'剩余' + scpre.hours + '时' }}</div> -->
      <template v-slot="timeData">
        <span class="colon">距考试结束: </span>
        <span class="block">{{ timeData.hours }}小时</span>
        <span class="block">{{ timeData.minutes }}分钟</span>
        <span class="block" style="color:#f00">{{ timeData.seconds }}</span>
        <span>秒</span>
      </template>
    </van-count-down>
    <!-- 题目 -->
    <div class="question" v-longpress :style="`padding-top:30px;padding-bottom:100px;background:${checked?'#000':'#fff'}`">
      <questions ref="questions" :examList="examList" :currentIndex="currentIndex" :isExercises="false" @question-result="questionResult" @question-index="questionIndex"></questions>
    </div>
    <div v-if="currentIndex === this.examList.length - 1" class="lastExam">已经到最后一题啦！</div>
    <!-- bottom -->
    <div class="btn" :style="`background:${checked?'#000':'#fff'}`">
      <div class="prev" @click="prev">上一题</div>
      <div class="next" @click="next">下一题</div>
    </div>
    <div class="bottom" :style="`background:${checked?'#000':'#fff'}`">
      <div  @click="submitExam" class="submit-paper-wrap topics" style="color:#18A6F3">
        <!-- <van-icon name="column"/><span class='submit-paper'>交卷</span> -->
        <icon-svg :iconClass="`${checked?'icon-shensemoshi-jiaojuan023x':'icon-qiansemoshi-jiaojuan023x'}`" className="icon-class"></icon-svg>
        <span style="font-size:12px;color:#999">交卷</span>
      </div>
      <div v-if="!isCollection" v-show="!isOldVersion" @click="collect" class="submit-paper-wrap topics" style="color:#18A6F3">
        <!-- <van-icon name="star-o" /><span class='submit-paper bt-collect'>收藏</span> -->
        <icon-svg :iconClass="`${checked?'icon-shensemoshi-weishoucang3x':'icon-qiansemoshi-weishoucang'}`" className="icon-class"></icon-svg>
        <span style="font-size:12px">收藏</span>
      </div>
      <div v-else v-show="!isOldVersion" @click="cancelCollect" class="submit-paper-wrap topics" style="color:#18A6F3">
        <!-- <van-icon name="star" /><span class='submit-paper'>取消</span> -->
        <icon-svg :iconClass="`${checked?'icon-shensemoshi-yishoucang3x':'icon-qiansemoshi-yishoucang'}`" className="icon-class"></icon-svg>
        <span style="font-size:12px;color:#999">收藏</span>
      </div>
      <div @click="openCorrect" class="submit-paper-wrap topics" style="color:#18A6F3">
        <icon-svg :iconClass="`${checked?'icon-shensemoshi-jiucuo3x':'icon-qiansemoshi-jiucuo3x'}`" className="icon-class"></icon-svg>
        <span style="font-size:12px;color:#999">纠错</span>
      </div>
      <!-- <div class="submit-paper-wrap" style="color:#18A6F3">
        <icon-svg :iconClass="`${checked?'icon-shensemoshi-beiti3x':'icon-qiansemoshi-beiti3x'}`" className="icon-class"></icon-svg>
        <span style="font-size:12px;color:#999">背题模式</span>
      </div> -->
      <div class="submit-paper-wrap topics" style="color:#18A6F3" @click="topicShow">
        <icon-svg :iconClass="`${checked?'icon-shensemoshi-tishu3x':'icon-qiansemoshi-tishu3x'}`" className="icon-class-small"></icon-svg>
        <span style="font-size:12px;color:#999">{{ currentIndex + 1 }}/{{ examTotal }}</span>
      </div>
    </div>
    <!-- 答题卡 -->
    <answer-sheet v-if="showAnswerSheet" @submit="xx" :answerCardList="answerCardList" :sumed="sumed" :answeredSum="answeredSum" :leftSum="leftSum" :isExercises="false" @close="topicHide" @to-current-exam="toCurrentExam"></answer-sheet>
    <!-- 纠错弹窗 -->
    <van-popup
    v-model="correctShow"
    closeable
    round
    close-icon="close"
    position="bottom"
    :style="{ height: '70%' }"
    :class="`correctPop ${checked?'night':'day'}`"
    >
    <h3 :style="`text-align:center;color:${checked?'#ddd':'#3D3D3D'}`">我要纠错</h3>
    <div class="correctType" :style="`color:${checked?'#ddd':'#3D3D3D'}`">纠错类型</div>
    <van-radio-group v-model="radio" direction="horizontal" style="display:flex;flex-wrap: wrap;">
        <van-radio name="0">题干或解析错误</van-radio>
        <van-radio name="1">解析不匹配</van-radio>
        <van-radio name="2">答案错误</van-radio>
        <van-radio name="3">其它</van-radio>
    </van-radio-group>
     <div class="correctType" :style="`color:${checked?'#ddd':'#3D3D3D'}`">我要补充</div>
     <van-field
        v-model="message"
        class="wybc"
        @focus="wyChange"
        rows="5"
        type="textarea"
        placeholder="请输入留言"
        :style="`background:${checked?'#404040':'#fff'}`"
        />
        <div class="submit" @click="correctSumbit">提 交</div>
    </van-popup>
    <van-action-sheet v-model="fontSizeSheetShow" title="" class="fontSheet">
      <div class="content-action-sheet">
        <div style="width: 100%;">
            <div class="nightBox">
                <span class="nightMode">夜间模式</span>
                <van-switch v-model="checked" size="24px" @change="changeMode"/>
            </div>
            <div class="wzsf">文字缩放</div>
          <!-- <van-slider v-model="progress" :min="-50" :max="50" /> -->
          <yxs-slider @change="onProgressChange" :multipleList="multipleList"/>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import Questions from './examQuestions/questions'
import { getQueryString } from '@/assets/js/util.js'
import AnswerSheet from './examAnswerSheet'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import yxsSlider from '@/components/yxsSlider'
function addAttr (richText) {
  try {
    if (!richText) return ''
    richText = String(richText)
    if (!richText.trim()) return ''
    const divNode = document.createElement('div')
    divNode.innerHTML = richText
    const pNodeList = divNode.getElementsByTagName('p')
    // debugger

    ;[].forEach.call(pNodeList, (pItem, index) => {
      if (pItem.children.length > 0) { // 去除<br>标签
        ;[].forEach.call(pItem.children, child => {
          if (child.tagName && child.tagName.toLocaleLowerCase() === 'br') {
            pItem.removeChild(child)
          }
        })
      }

      if (pItem.childNodes.length === 1 && pItem.firstChild.tagName && pItem.firstChild.tagName.toLocaleLowerCase() === 'img') {
        pItem.setAttribute('block-img', 'true')
      }
    })
    return divNode.innerHTML
  } catch (e) {
    console.error(e)
    return richText
  }
}
function addPrefix (richText, prefix) {
  try {
    if (!richText) return ''
    richText = String(richText)
    if (!richText.trim()) return richText
    const divNode = document.createElement('div')
    divNode.innerHTML = richText
    const pNodeList = divNode.getElementsByTagName('p')
    const spanNode = document.createTextNode(prefix + '')
    if (pNodeList.length > 0) { // 富文本
      const pNode = pNodeList[0]
      if (pNode.firstChild) {
        pNode.insertBefore(spanNode, pNode.firstChild)
      } else {
        pNode.appendChild(spanNode)
      }
    } else { // 老题，非富文本
      divNode.insertBefore(spanNode, divNode.firstChild)
    }
    return divNode.innerHTML
  } catch (e) {
    return richText
  }
}
export default {
  components: {
    Questions,
    AnswerSheet,
    yxsSlider
  },
  data () {
    return {
      richText: "<p>无</p><p><img src='https://file.zhihuischool.cn/f38e0707035243fbb28a5687a289cfaf.png' title='' alt=''/></p>",
      isWiating: false,
      showCountdown: false,
      show: false,
      isCollection: '',
      sumList: [],
      examList: [],
      answerCardList: [],
      examTotal: 0,
      currentExam: {},
      currentIndex: 0,
      showAnswerSheet: false,
      current: 1,
      size: 10,
      timeLeft: '',
      isClickToLast: false, // 是否点击题目到的最后一题
      lessonId: '', // 课时id
      chapterId: '',
      courseId: '',
      resultId: '',
      needMarking: '',
      isOldVersion: false,
      correctShow: false, // 纠错弹窗
      radio: '0',
      message: '',
      fontSizeSheetShow: false,
      multipleList: [
        {
          multiple: 0.8,
          name: '小'
        },
        {
          multiple: 1,
          name: '标准',
          default: true
        },
        {
          multiple: 1.5,
          name: '大'
        },
        {
          multiple: 2,
          name: '超大'
        }
      ],
      checked: false
    }
  },
  beforeDestroy () {

  },
  watch: {
    '$store.state.isGetToken' (val) {
      let type = this.$store.state.deviceType
      if (val && type === 1) {
        window.webkit.messageHandlers.getData.postMessage('courseId')
        window.getIosId = this.getIosId
      } else if (val && type === 2) {
        this.getAnId()
      } else {

      }
    }
  },
  computed: {
    ...mapGetters({ userSetting: 'userSetting' }),

    sumed () {
      // let uniqueArr = this.answerCardList.filter(item => (item.rightFlag === 1 || item.rightFlag === '1' || item.rightFlag === '0' || item.rightFlag === 0))
      // return uniqueArr.length
      return this.currentIndex + 1
    },
    answeredSum () {
      return this.answerCardList.filter(item => !!item.userAnswer).length
    },
    leftSum () {
      return this.answerCardList.filter(item => !item.userAnswer).length
    }
  },
  mounted () {
    // document.querySelector('#app').style.overflow = 'hidden'
  },
  created () {
    console.log(32);
    // this.getPaper(14578)
    console.log(32);
    // if (!this.userSetting.colorModeExam) {
    //   this.changeUserSetting({ 'colorModeExam': 'day' })
    // }
    // window.openModeSet = () => {
    //   this.fontSizeSheetShow = true
    // }
    // window.mFontSizeSetting = () => {
    //   this.fontSizeSheetShow = true
    // }
    // this.show = window.localStorage.getItem('tipShow') || false
    // this.userToken = getQueryString('userToken') || ''
    // this.tenantId = getQueryString('tedantId') || ''
    // this.examId = getQueryString('examId') || ''
    // this.lessonId = getQueryString('lessonId') || ''
    // this.chapterId = getQueryString('chapterId') || ''
    // this.courseId = getQueryString('courseId') || ''
    // this.questionFrom = getQueryString('questionFrom')
    // this.title = getQueryString('title') || '考试'


    // console.log('title', this.title)
    // document.title = this.title
    // console.log('document.title', document.title)
    // 夜间模式按钮回显
    // console.log('this.userSetting.colorModeExam', this.userSetting.colorModeExam)


    // if (this.userSetting.colorModeExam === 'day') {
    //   this.checked = false
    // } else {
    //   this.checked = true
    // }
    // if (!this.questionFrom && this.questionFrom !== '0' && this.questionFrom !== 0) {
    //   this.isOldVersion = true
    // }

    // 调试开发用参数 TODO: 上传前注释
    // this.userToken = '2a4291bd-ca51-48b5-863f-d23885516013'
    // // this.tenantId = 52
    // this.lessonId = 5660
    // this.examId = 13
    // this.questionFrom = 0

    // 是否重做
    // this.reForm = getQueryString('reform') || false

    // this.$store.commit('setToken', this.userToken)
    // this.$store.commit('setTenantId', this.tenantId)
    this.getAnswerCardList()

    // if (this.userSetting.fontMultipleExam) {
    //   this.multipleList = this.multipleList.map(item => ({ ...item, default: item.multiple === this.userSetting.fontMultipleExam }))
    // }


  },
  methods: {
    ...mapActions(['changeUserSetting']),
    // *****************************智汇-新增开始
    // Android
    getAnId () {
      // alert('getWorkid: ' + window.Android.getWorkid())
      let getWorkid = window.Android.getWorkid()
      this.getPaper(getWorkid)
    },
    // ios
    getIosId (getWorkidgetWorkid) {
      // alert('getWorkidgetWorkid: ' + getWorkidgetWorkid);
      this.getPaper(getWorkidgetWorkid)
    },
    // 获取课程成绩
    getPaper (workId) {
      // workid = 14578
      this.$request.getWorkList({ workId }).then(res => {
        if (res.data.code === 0) {
          this.courseData = res.data.data
        }
      })
    },

    // *****************************智汇-新增结束
    wyChange () {
      console.log(11111)
      // window.addEventListener('resize', () => {
      // if (document.activeElement.tagName == 'INPUT') {
      //   window.setTimeout(() => {
      //     console.log(555)
      //     document.activeElement.scrollIntoViewIfNeeded()
      //     console.log(666)
      //   }, 500)
      // }
      // })
      // document.querySelector('.wybc').scrollIntoView()
    },
    // 夜间模式切换
    changeMode (mode) {
      var str = ''
      if (mode) {
        str = 'night'
        // window.location.href = 'sendmessage/setcolormodeexam?mode=night'
      } else {
        str = 'day'
        // window.location.href = 'sendmessage/setcolormodeexam?mode=day'
      }
      this.changeUserSetting({ 'colorModeExam': str })
      this.examList = this.examList.map(ele => {
        if (ele.typeId === 7) {
          return {
            ...ele,
            colorMode: str,
            childrenList: ele.childrenList.map(item => ({
              ...item,
              colorMode: str
            }))
          }
        }
        return {
          ...ele,
          colorMode: str
        }
      })
    },
    onProgressChange (multipeInfo) {
      console.log(multipeInfo)
      this.changeUserSetting({ 'fontMultipleExam': multipeInfo.multiple })
      this.examList = this.examList.map(ele => {
        if (ele.typeId === 7) {
          return {
            ...ele,
            fontSizeMultiple: multipeInfo.multiple,
            childrenList: ele.childrenList.map(item => ({
              ...item,
              fontSizeMultiple: multipeInfo.multiple
            }))
          }
        }
        return {
          ...ele,
          fontSizeMultiple: multipeInfo.multiple
        }
      })
    },
    /**
     * 获取答题卡列表
     */
    getAnswerCardList () {
      Toast.loading({
        duration: 0,
        message: '题目生成中'
      })
      let workId = 14578
      console.log(workId)
      this.$request.getWorkList({ workId }).then(res => {
          if (res.code !== 0) {
            return
          }
          if (Date.parse(new Date(res.data.examResult.endTime)) - Date.parse(new Date(res.data.examResult.updateTime)) < 0) {
            // TODO: 处理考试已结束的情况
          }

          if (!res.data.examResult.updateTime) {
            res.data.examResult.updateTime = res.data.examResult.createTime // 如果 updateTime 为空，便用createTime计算考试倒计时
          }

          // 考试剩余时间
          this.timeLeft = Date.parse(new Date(res.data.examResult.endTime.replace(/-/g, '/'))) - Date.parse(new Date(res.data.examResult.updateTime.replace(/-/g, '/')))

          this.showCountdown = true
          this.$nextTick(() => {
            this.$refs['count-down'].start() // 开始计时
          })

          res.data.questionList = res.data.questionList.map((question, childrenIndex) => {
            if (question.typeId === 1 || question.typeId === 2 || question.typeId === 3) { // 单选，多选，判断
              return {
                ...question,
                optionContents: JSON.stringify(JSON.parse(question.optionsContent).map(optionItem => ({ ...optionItem, content: addAttr(optionItem.content) }))),
                stem: addAttr(addPrefix(question.stem, childrenIndex + 1 + '.')),
                analysis: addAttr(question.analysis),
                fontSizeMultiple: this.userSetting.fontMultipleExam ? this.userSetting.fontMultipleExam : 1,
                colorMode: this.userSetting.colorModeExam ? this.userSetting.colorModeExam : 'day'
              }
            } else {
              return {
                ...question,
                stem: addAttr(addPrefix(question.stem, childrenIndex + 1 + '.')),
                analysis: addAttr(question.analysis),
                fontSizeMultiple: this.userSetting.fontMultipleExam ? this.userSetting.fontMultipleExam : 1,
                colorMode: this.userSetting.colorModeExam ? this.userSetting.colorModeExam : 'day'
              }
            }
          })

          res.data.questionList = res.data.questionList.map(question => {
            if ((question.typeId === 7 && question.childrenList && question.childrenList.length > 0) || (question.typeId === 8 && question.childrenList && question.childrenList.length > 0)) {
              return {
                ...question,
                userAnswer: question.childrenList.some(item => item.userAnswer),
                childrenList: question.childrenList.map((childrenItem, childrenIndex) => {
                  if (childrenItem.typeId === 1 || childrenItem.typeId === 2 || childrenItem.typeId === 3) { // 单选，多选，判断
                    return {
                      ...childrenItem,
                      optionContents: JSON.stringify(JSON.parse(childrenItem.optionsContent).map(optionItem => ({ ...optionItem, content: addAttr(optionItem.content) }))),
                      stem: addAttr(addPrefix(childrenItem.stem, '（' + (childrenIndex + 1) + '）')),
                      analysis: addAttr(childrenItem.analysis),
                      fontSizeMultiple: this.userSetting.fontMultipleExam ? this.userSetting.fontMultipleExam : 1,
                      colorMode: this.userSetting.colorModeExam ? this.userSetting.colorModeExam : 'day'
                    }
                  } else {
                    return {
                      ...childrenItem,
                      stem: addAttr(addPrefix(childrenItem.stem, '（' + (childrenIndex + 1) + '）')),
                      analysis: addAttr(childrenItem.analysis),
                      fontSizeMultiple: this.userSetting.fontMultipleExam ? this.userSetting.fontMultipleExam : 1,
                      colorMode: this.userSetting.colorModeExam ? this.userSetting.colorModeExam : 'day'
                    }
                  }
                })
              }
            }
            return {
              ...question,
              childrenList: []
            }
          })

          console.log(res.data.questionList, '<><<<<')

          if (_.isArray(res.data.questionList) && res.data.questionList.length > 0) {
            // TODO: 处理试卷没有考题的情况
          }

          if (res.data.questionList.every(item => (item.answer))) { // 题目全部答完了
            // TODO: 处理试题全部做完了的情况
          }

          const lastQuestionIndex = _.findIndex(res.data.questionList, item => (item.lastFlag === 1 || item.lastFlag === '1'))

          this.$refs.questions.$refs.swiper.swipeTo(lastQuestionIndex)
          console.log('cardList', _.cloneDeep(res.data))
          this.answerCardList = res.data.questionList
          this.examList = res.data.questionList
          this.examTotal = this.answerCardList.length
          this.resultId = res.data.examResult.id
          this.needMarking = res.data.needMarking
          this.init()
        })
        .finally(() => {
          Toast.clear()
        })
    },
    /**
     * 倒计时交卷
     */
    onExamFinished () {
      console.log('提交试卷')
      this.$api.exam.submitExam({ examPaperId: this.examId, examResultId: this.resultId, needMarking: this.needMarking })
        .then(res => {
          // TODO: 接口返回错误处理
          Dialog.alert({
            title: '温馨提示',
            message: '考试结束，已自动提交试卷'
          }).then(() => {
            //  window.location.href = 'sendmessage/setcolormodeexam?mode=day'
            // this.$router.replace({ path: '/exam/examResult', query: { examResultId: this.resultId, userToken: this.userToken, tedantId: this.tenantId } })
            window.location.replace(`/exam/examResult?examResultId=${this.resultId}&userToken=${this.userToken}&tedantId=${this.tenantId}`)
          })
        })
    },
    /**
     * 主动交卷
     */
    submitExam () {
      Dialog.confirm({
        title: '温馨提示',
        message: '确定提交试卷吗？',
        className: `${this.userSetting.colorModeExam === 'day' ? 'day' : 'night'}`
      })
        .then(() => {
          console.log('confirm')
          console.log('examId')
          console.log(this.examId)
          console.log('examId')
          console.log('examResultId')
          console.log(this.examResultId)
          console.log('examResultId')
          console.log('needMarking')
          console.log(this.needMarking)
          console.log('needMarking')
          console.log('提交试卷')
          this.$api.exam.submitExam({ examPaperId: this.examId, examResultId: this.resultId, needMarking: this.needMarking })
            .then(res => {
              // TODO: 接口异常处理
              Dialog.alert({
                title: '温馨提示',
                message: '已提交试卷'
              }).then(() => {
                this.$router.replace({ path: '/exam/examResult', query: { examResultId: this.resultId, userToken: this.userToken, tedantId: this.tenantId } })
              })
            })
        })
        .catch(() => {
          console.log('cancel')
        })
    },
    xx () {

    },
    clickKnow () {
      this.show = true
      // 引导页载入缓存
      window.localStorage.setItem('tipShow', true)
    },
    questionResult (val) {
      if ((val.parentQuestion && val.parentQuestion.typeId == 7) || (val.parentQuestion && val.parentQuestion.typeId == 8)) {
        console.log('进入了配伍题提交')
        console.log(val)
        console.log('进入了配伍题提交')
        this.saveRecord(val)
          .then(res => {
            this.examList[this.currentIndex].userAnswer = true
            this.answerCardList = this.examList
            return this.saveRecord({ ...val.parentQuestion, answer: true })
          })
          .then(res => {
            console.log(res, '<<<<<')
          })
      } else {
        this.saveRecord(val)
          .then(res => {
            this.examList[this.currentIndex].userAnswer = val.userAnswer
            this.answerCardList = this.examList
            if (this.currentIndex === this.examList.length - 1) {
              Toast({
                message: '已经到最后一题啦！',
                duration: 1000
              })
            }
            if (val.typeId === 1 || val.typeId === 4) {
              this.$nextTick(_ => {
                this.$refs.questions.$refs.swiper.next()
              })
            }
          })
      }
      // 练习题保存答案
    },
    questionIndex (val) {
      this.currentIndex = val
      this.init()
    },
    init () {
      this.currentExam = this.examList[this.currentIndex]
      console.log(this.currentExam, '9999')
      this.isCollection = this.currentExam.collectionStatus === 1 || this.currentExam.collectionStatus === '1'
    },
    // 纠错
    openCorrect () {
      this.message = ''
      this.radio = '0'
      this.correctShow = true
    },
    correctSumbit () {
      this.$api.exam.correct({
        questionId: this.examList[this.currentIndex].id,
        questionType: this.examList[this.currentIndex].questionType,
        type: this.radio,
        title: this.message,
        lessonId: this.lessonId,
        chapterId: this.chapterId,
        courseId: this.courseId
      }).then(res => {
        console.log(res, '纠错')
        if (res.code === 0 && res.data) {
          Toast({
            message: '纠错成功',
            duration: 1000
          })
          this.correctShow = false
        }
      })
    },
    // 收藏
    collect () {
      this.$api.exam.examCollect({
        examPaperId: this.examId,
        questionFrom: this.questionFrom,
        questionId: this.examList[this.currentIndex].id,
        typeId: this.examList[this.currentIndex].typeId
      })
        .then(res => {
          console.log('收藏', res)
          if (res.code === 0 && res.data) {
            Toast({
              message: '收藏成功',
              duration: 1000
            })
            this.examList[this.currentIndex].collectionId = res.data.collectionId
            this.examList[this.currentIndex].collectionStatus = 1
            this.init()
          } else {
            Toast({
              message: res.msg,
              duration: 1000
            })
          }
        })
    },
    // 取消收藏
    cancelCollect () {
      this.$api.exam.examUncollect({
        examPaperId: this.examId,
        questionFrom: this.questionFrom,
        questionId: this.examList[this.currentIndex].id
      })
        .then(res => {
          if (res.code === 0 && res.data) {
            Toast({
              message: '取消收藏成功',
              duration: 1000
            })
            this.isCollection = 0
            this.examList[this.currentIndex].collectionStatus = 0
            this.init()
          } else {
            Toast({
              message: res.msg,
              duration: 1000
            })
          }
        })
    },
    // 保存做题记录
    saveRecord (question) {
      return this.$api.exam.saveAnswer({
        examPaperId: this.examId,
        questionId: question.id,
        score: question.score,
        answer: question.userAnswer,
        typeId: question.typeId,
        refAnswer: question.answer,
        resultId: this.resultId,
        parentId: question.parentId
      })
    },
    // 上一题
    prev () {
      if (this.currentIndex === 0) {
        Toast({
          message: '已经到第一道题啦!',
          duration: 1000
        })
        return
      }
      this.$nextTick(_ => {
        this.$refs.questions.$refs.swiper.prev()
      })
    },
    // 下一题
    next () {
      if (this.currentIndex === this.examList.length - 1) {
        Toast({
          message: '已经到最后一题啦！',
          duration: 1000
        })
      }
      this.$nextTick(_ => {
        this.$refs.questions.$refs.swiper.next()
      })
    },
    topicShow () {
      this.showAnswerSheet = true
    },
    topicHide () {
      this.showAnswerSheet = false
    },
    // 跳转到指定题目
    toCurrentExam (index) {
      this.isClickToLast = true
      this.$nextTick(_ => {
        this.$refs.questions.$refs.swiper.swipeTo(index)
        this.topicHide()
      })
    }
  }
}
</script>
<style lang="scss">
.van-dialog.night{
  background: #404040;
  .van-dialog__header{
    color: #ddd;
  }
  .van-dialog__message{
    color: #ddd;
  }
  .van-button{
    background: #404040;
    color: #ddd;
    &.van-dialog__confirm{
      color: #1989fa;
    }
  }
}
.van-cell:not(:last-child)::after{
  border-bottom: none!important;
}
.exam-container {
  .van-swipe-item {
    background: #fff;
  }
  &.night{
    .van-swipe-item {
      background: #000;
    }
  }
//   .fontSheet.van-action-sheet{
//       height: 173px;
//   }
}
</style>
<style lang="scss" scoped>
    .icon-class-small {
      width: 18px !important;
      height: 18px !important;
    }
    .topics {
      font-size: 12px;
      color: #333;
      span {
        color: #999;
      }
      svg {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto 5px;
      }
    }
    .exam-container{
        ::v-deep .content-action-sheet {
            // height: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px;
            box-sizing: border-box;
            .nightBox{
                height:30px;
                display:flex;
                align-items:center;
                justify-content: space-between;
                padding: 15px 0;
                    .nightMode{
                        font-size:14px;
                        font-family:PingFangSC-Semibold,PingFang SC;
                        font-weight:600;
                        color:rgba(61,61,61,1);
                }
            }
            .wzsf{
                height:18px;
                font-size:12px;
                font-family:PingFangSC-Semibold,PingFang SC;
                font-weight:600;
                color:rgba(61,61,61,1);
                line-height:18px;
                padding: 15px 0;
            }
        }
    }
  .exam-countdown {
    padding: 5px;
    text-align: center;
  }
  .container {
    width: 100%;
    height: 100vh;
    background: #fff;
    padding:0;
  }
  .shadow {
    width: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: .4);
    z-index: 11;
    .tip {
      padding: 255px 15px 0;
      overflow: hidden;
      color: #eee;
      .left {
        float: left;
        img {
          display: block;
          width: 42px;
          height: 60px;
          padding-top: 22px;
          margin: 0 auto;
        }
      }
      .right {
        float: right;
        img {
          display: block;
          width: 38px;
          height: 60px;
          padding-top: 22px;
          margin: 0 auto;
        }
      }
    }
    .know {
      width: 127px;
      line-height: 40px;
      margin: 83px auto 0;
      background: #fff;
      color: #18A6F3;
      text-align: center;
      border: 1px solid #18A6F3;
      border-radius: 20px;
    }
  }
  .lastExam {
    margin-top: 50px;
    width: 100%;
    font-size: 15px;
    color: #c6c6c6;
    text-align: center;
  }
  .bt-collect {
    text-align: center;
  }
  .bottom {
    position: fixed;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    width: 348px;
    height: 32px;
    padding: 13px 12px 13px 15px;
    box-shadow: 0 2px 4px 0 rgba($color: #000000, $alpha: .2);
    background: #fff;
    align-items: center;
    .collect {
      width: 48px;
      font-size: 12px;
      color: #333;
      text-align: center;
      img {
        display: block;
        width: 14px;
        height: 13px;
        margin: 0 auto 5px;
      }
    }
    .submit {
      font-size: 12px;
      color: #18A6F3;
      img {
        display: block;
        width: 14px;
        height: 14px;
        margin: 0 auto 5px;
      }
    }
    .submit-paper-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .submit-paper {
      font-size: 13px;
    }
    .topics {
      font-size: 12px;
      color: #333;
      span {
        color: #999;
      }
      img {
        display: block;
        width: 14px;
        height: 14px;
        margin: 0 auto 5px;
      }
    }
  }
</style>
<style lang="scss">
.exam-container {
  .van-count-down {
    height: 22px;
    box-sizing: border-box;
    color: #aaa;
    font-size: 12px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: inline-block;
    padding: 0 10px;
    margin-top: 8px;
  }
}
  .van-dialog__header {
    padding-top: 8px;
  }
  .btn{
      display: flex;
      justify-content: space-around;
      position: fixed;
      bottom:58px;
      width: 100%;
      z-index: 3;
      background-color: #fff;
      padding: 3px 0;
    .prev {
      // width: 80px;
      // line-height: 30px;
      // font-size: 13px;
      // color: #18A6F3;
      // text-align: center;
      // border: 1px solid #18A6F3;
      // border-radius: 17px;
      // box-sizing: border-box;
      width:108px;
      height:34px;
      border-radius:6px;
      border:1px solid rgba(23,125,219,1);
      text-align: center;
      font-size:13px;
      box-sizing: border-box;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(23,125,219,1);
      line-height:34px;
      margin-right: -80px;
    }
    .next {
      // width: 80px;
      // line-height: 30px;
      // font-size: 13px;
      // color: #fff;
      // text-align: center;
      // background: #18A6F3;
      // border-radius: 17px;
      width:108px;
      height:34px;
      background:rgba(23,125,219,1);
      border-radius:6px;
      text-align: center;
      font-size:13px;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(247,248,249,1);
      line-height:34px;
    }
  }
  .correctType{
        height:20px;
        font-size:16px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(138,138,142,1);
        line-height:20px;
        margin-top: 20px;
        padding-left: 20px;
        margin-bottom: 15px;
  }
  .content .van-radio__label{
    word-break: break-all;
  }
  .correctPop{
    &.night{
      background: #404040;
      .van-radio__label{
        color:#ddd;
      }
      .van-field__control{
        background: #ccc;
      }
    }
    &.day{
      background: #fff;
    }
      .van-radio:nth-child(1),.van-radio:nth-child(3){
            width: 48%;
            height: 40px;
            margin-left: 20px;
            line-height: 40px;
      }
      .van-radio:nth-child(2),.van-radio:nth-child(4){
            height: 40px;
            line-height: 40px;
      }
      .van-field__control{
          background: rgba(247,247,247,1);
          border-radius:16px;
          text-indent: 20px;
      }
      .submit{
            width:170px;
            height:36px;
            background:rgba(24,166,243,1);
            border-radius:4px;
            font-size:16px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(255,255,255,1);
            line-height:36px;
            margin-left: calc(50% - 85px);
            text-align: center;
            margin-top: 5px;
      }
  }
</style>
